import moment from 'moment';
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { stepLogSlice } from '../audit_log/auditSlice';

export function NavigationArrow() {
    const timestamp = useAppSelector(state => state.audit.auditStep.timestamp);
    let formattedAuditStepTimestamp = moment(timestamp).format("YYYY-MM-DD");
    const dispatch = useAppDispatch();
    const handleArrowNavigation = async (direction: string) => {
        dispatch(stepLogSlice(direction));
  
      };

    return (
        
        // Left arrow
        <svg>
            <text id="timestamp" x="30" y="10" fontFamily="Avenir Next" fontSize="60" fontWeight="600" fill="black">
                {timestamp ? formattedAuditStepTimestamp : "Timestamp"}</text>
                <g>
                <svg onClick={() => handleArrowNavigation("BACKWARDS")} fill="#005599" height="100px" width="30px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0" y="2" ><g id="SVGRepo_bgCarrier" strokeWidth="0"><rect x="0" y="0" width="8.00" height="48.00" rx="0" fill="#ffffff" strokeWidth="0"></rect></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g>
                <polygon id="leftArrow" transform="scale(0.04) translate(0, 0)" points="332.668,490 82.631,244.996 332.668,0 407.369,76.493 235.402,244.996 407.369,413.507 "></polygon> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </g> </g></svg>
                </g>
                <div id="leftArrow">
                    <svg fill="#005599" height="18px" width="18px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 490"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <polygon points="332.668,490 82.631,244.996 332.668,0 407.369,76.493 235.402,244.996 407.369,413.507 "></polygon></g></g></g></svg>
                </div> 
       
            
            <text id="timestamp" x="30" y="25" fontFamily="Avenir Next" fontSize="60" fontWeight="600" fill="black">
                {timestamp ? "T" + new Date(timestamp).getUTCHours() + ":" + 
            (new Date(timestamp).getUTCMinutes()) + ":"
            + new Date(timestamp).getUTCSeconds() + "."
            + new Date(timestamp).getUTCMilliseconds() + "Z" : ""}
            
            </text>
             {/* Right arrow */}
             <g>
                <svg onClick={() => handleArrowNavigation("FORWARDS")} fill="#005599" height="60px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="130" y="0" ><g id="SVGRepo_bgCarrier" strokeWidth="0"><rect x="0" y="0" width="188.00" height="8.00" rx="0" fill="#ffffff" strokeWidth="0"></rect></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g>
                <polygon id="rightArrow" transform="scale(0.04) translate(400, 520) rotate(180)" points="332.668,490 82.631,244.996 332.668,0 407.369,76.493 235.402,244.996 407.369,413.507 "></polygon> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </g> </g></svg>
            </g>
        </svg>
        
        
    )
}
// SVG Graph of actors of the demo scenario.  Shows the actors with lines, arrows, etc.
//
// Implements the SvgGraph tag, that will create a SVG Graph of the current scenario, given
// a DemoGraphState, which is based on several selectors in the encompasing form.
//
// TODO:
//   * Single
//     * Switching magnification changes resize so document windows either stretch or grow. Need to fix the to do.
//     * save svg from fontawsome into the assets folder, and then usem in code
//     - re-organize code, consistent variables
//     * Abstract graph to work with multiple layouts: Simple | CBDS | ...
//   * Pairing
//     * Size of the SVG element should follow the available space

import React, {createContext, ReactElement, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useAppSelector} from '../../app/hooks';
import {ActorLedger} from "./ActorLedger";
import {useResizeObserver} from "./Resize";
import {formDataFromState, getDocumentDetails} from "./documentDetails";
import {DocumentIcon, LedgerIcon} from "./icons";
import {
   actorNameToRow, actorNameToText,
   ActorNameType,
   actorToBotY,
   actorToCenterX,
   actorToCenterY,
   actorToHeight,
   actorToLeftX, actorToRightX,
   actorToTopY,
   actorToWidth, ActorType,
   centerY,
   DemoActor, DemoScenario, getScenarioActors, getScenarioActorTypes, nameToDemoActor,
} from "./DemoActor";
import {DocumentType, getScenarioDocuments} from "./AtomicNetDocument";
import {AuditLogTime, DemoGraphState} from "./DemoGraphState";
import {DocumentOrdering} from "../audit_log/auditSlice";
import {FiatPerAsset} from "../audit_log/ledgerEvents";

type DocState = {
   values: {[documentName: string]: boolean},
   update: (docState: DocState, docName: string) => void,
}
const DocStateContext = createContext<DocState>({ values: {}, update: (docState, docName) => {
} });

const GraphWidth = 1500;
const GraphHeight = 800

export const ColumnWidth = 140;
export const ColumnOffset = 10;
export const RowOffset = 25;
export const RowHeight = 100;

export const ActorWidth = 180;
export const ActorHeight = 30;

const DocumentHeight = 20;
const DocumentWidth = 25;
const DocumentDetailsHeight = 100;
const DocumentDetailsWidth = 350;

const ArrowBase = 7;
const ArrowLength = 10;

export function SvgGraph () {
   let sender = useAppSelector(state => state.audit.sender) as ActorNameType;
   let receiver = useAppSelector(state => state.audit.receiver) as ActorNameType;
   let displayNameMap = useAppSelector(state => state.audit.displayNameMap);
   let ledgerState = useAppSelector(state => state.audit.ledgerState);
   let orderTicket = useAppSelector(state => state.audit.orderTicket);
   let auditState = useAppSelector(state => state.audit.auditStep);
   let auditLogCount = useAppSelector(state => state.audit.auditEntries.length);
   let scenario = useAppSelector(state => state.audit.scenario);

   // Set the starting state of the graph: This will need to be set by
   // clicking log lines.
   const demoGraphStateInput: DemoGraphState = {
      selectedSrc: [sender],
      selectedDst: [receiver],
      selectedLog: auditState,
      auditLogCount: auditLogCount,
      flows: [
         {start: sender, end: receiver},
      ],
      // custodian1 is Custodian, custodian2 is Bank
      displayNames: {
         ia1: displayNameMap.ia1,
         ia2: scenario === "no_exchange" ? "Liquidity Provider" : "Counterparty",
         oms1: displayNameMap.oms1, // have oms1 show as empty
         oms2: scenario === "no_exchange" ? "Liquidity Provider OMS" : "Counterparty OMS",
         custodian1: displayNameMap.custodian1, // example of a fill in for custodian1
         custodian2: scenario === "no_exchange" ? displayNameMap.custodian2 : "Counterparty Custodian",
         common_ledger1: displayNameMap.common_ledger1,
         common_ledger2: displayNameMap.common_ledger2,
         exchange: displayNameMap.exchange,
         abc: displayNameMap.abc, // always want ABC to be all caps
      },
      ledgerEvents: ledgerState,
      asset1: orderTicket.fiatCurrency,
      asset2: orderTicket.asset ? orderTicket.asset[0] : "",
      assetAmount1: orderTicket.quantity * FiatPerAsset,
      assetAmount2: orderTicket.quantity,
      scenario: scenario,
   };

   const [docState, setDocState] = useState<DocState>({
      values: { ba1: false, ba2: false, ec1: false, ec2: false, ob1: false, ob2: false, },
      update: (docState, docName) => {
         setDocState({
            values: {...docState.values, [docName]: !docState.values[docName]},
            update: docState.update,
         });
      }
   });

   return (<>
      <DocStateContext.Provider value={docState}>
         <SvgGraphInternal demoGraphState={demoGraphStateInput}/>
      </DocStateContext.Provider>
      </>)
}

// Internal implementation of SvgGraph.
//
// Creates the SVG based on parameters in DemoGraphState.
function SvgGraphInternal ({demoGraphState}: {demoGraphState: DemoGraphState}) {
   const [showCustodianLedgerLeft, setShowCustodianLedgerLeft] = useState<boolean>(false);
   const [showCustodianLedgerRight, setShowCustodianLedgerRight] = useState<boolean>(false);
   const [showCommonLedgerLeft, setShowCommonLedgerLeft] = useState<boolean>(false);
   const [showCommonLedgerRight, setShowCommonLedgerRight] = useState<boolean>(false);
   const clickMap: { [actor: string]: { show: boolean, onClick: (e: React.MouseEvent) => void}} = {
      custodian1: {
         show: showCustodianLedgerLeft,
         onClick: useCallback((e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            setShowCustodianLedgerLeft(!showCustodianLedgerLeft);
         }, [showCustodianLedgerLeft]),
      },
      custodian2: {
         show: showCustodianLedgerRight,
         onClick: useCallback((e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            setShowCustodianLedgerRight(!showCustodianLedgerRight);
         }, [showCustodianLedgerRight]),
      },
      common_ledger1: {
         show: showCommonLedgerLeft,
         onClick: useCallback((e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            setShowCommonLedgerLeft(!showCommonLedgerLeft);
         }, [showCommonLedgerLeft]),
      },
      common_ledger2: {
         show: showCommonLedgerRight,
         onClick: useCallback((e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            setShowCommonLedgerRight(!showCommonLedgerRight);
         }, [showCommonLedgerRight]),
      },
   };
   const scenario = demoGraphState.scenario;

   return (<svg width={GraphWidth} height={GraphHeight}>
      <defs>
         <marker id="start_arrow" markerWidth={ArrowLength} markerHeight={ArrowBase} refX="0" refY={ArrowBase/2} orient="auto">
            {/* arrow pointing to the left: TR,BR,ML (refX, refY)*/}
            <polygon points={`${ArrowLength},0 ${ArrowLength},${ArrowBase} 0,${ArrowBase/2}`}/>
         </marker>
         <marker id="end_arrow" markerWidth={ArrowLength} markerHeight={ArrowBase} refX={ArrowLength} refY={ArrowBase/2} orient="auto">
            {/* arrow pointing to the right: TL,RM (refX, refY),LB */}
            <polygon points={`0,0 ${ArrowLength},${ArrowBase/2} 0,${ArrowBase}`}/>
         </marker>
      </defs>
      {
         [
            ...getScenarioActorTypes(scenario).map((actorName, index) => (<SimpleRowHeaderText key={actorName} actorName={actorName} index={index} scenario={scenario}/>)),
            ...getScenarioActors(scenario).map((demoActor) => (<ActorGraphArcs key={`arc_${demoActor.name}`} srcActor={demoActor} state={demoGraphState}/>)),
            ...getScenarioActors(scenario).map((demoActor) => (<ActorGraphLines key={`line_${demoActor.name}`} srcActor={demoActor} state={demoGraphState}/>)),
            ...getScenarioActors(scenario).map((demoActor) => {
               let mapping = clickMap[demoActor.name];
               const onClick = mapping !== undefined ? mapping.onClick : undefined;

               return (<ActorGraphRect
                   key={`rect_${demoActor.name}`}
                   demoActor={demoActor}
                   state={demoGraphState}
                   {...(onClick ? {gridClick: onClick}: {})}
               />);
            }),
            ...[...Object.entries(getScenarioDocuments(scenario))]
                .sort(([docNameA, docA], [docNameB, docB]) => {
                   const {x: xA, y: yA} = getDocumentCoordinates(docNameA, scenario);
                   const {x: xB, y: yB} = getDocumentCoordinates(docNameB, scenario);

                   return xA !== xB
                           ? Math.sign(xB - xA)
                           : Math.sign(yB - yA);
                })
                .map(([doc, displayAs]) => GraphDocumentAndContents(doc as DocumentType, demoGraphState)),
            ...getScenarioActors(scenario).filter((demoActor) => demoActor.actorType === "custodian" || demoActor.actorType === "common_ledger")
                .map((demoActor) => {
                   const show = clickMap[demoActor.name].show;
                   const onClick = clickMap[demoActor.name].onClick;

                   return show ? (<ActorGraphLedger key={`ledger_${demoActor.name}`} demoActor={demoActor} state={demoGraphState} onClick={onClick}/>) : (<></>);
            }),
         ]
      }
   </svg>);
}

// Graph the given document, and window that shows the contents of the document (if the content window is open).
//
//  * Highlight it based on the given displayAs (past, present, future or hidden).
//  * Draw the Document Contents window if visible (depending on click state).
function GraphDocumentAndContents(
   documentType: DocumentType,
   state: DemoGraphState
) {
   const docState = useContext(DocStateContext);

   const clickDocument = useCallback((e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      e.nativeEvent.stopImmediatePropagation();

      docState.update(docState, e.currentTarget.id);
   }, [docState]);

   const showDocument = docState.values[documentType];

   return (<>
          {graphDocument(documentType, state, clickDocument)}
          {graphDocumentContents(documentType, clickDocument, showDocument, state)}
      </>
   )
}

// Graph the given document. Highlight it based on on the given displayAs (past, present, future or hidden).
function graphDocument(
    documentName: DocumentType,
    state: DemoGraphState,
    docClick: (e: React.MouseEvent) => void) {
   const scenario = state.scenario;
   const document = getScenarioDocuments(scenario)[documentName];
   const srcActor = nameToDemoActor(scenario)[document.srcActor];
   const dstActor = nameToDemoActor(scenario)[document.dstActor];
   const envelope = document.subDocuments !== undefined && document.subDocuments.size !== 0;
   const connector_type = getActorConnectorType(srcActor, dstActor);

   let displayAs: AuditLogTime = "past";

   let docOrdering = DocumentOrdering.get(documentName);

   if (docOrdering === undefined) {
      let displayName = documentName as string;
      if (displayName.match(/2$/)) {
         displayName = displayName.replace(/2$/, "1");
         docOrdering = DocumentOrdering.get(displayName as DocumentType);
      }
   }

   if (state.selectedLog === undefined) {
      displayAs = "hidden";
   } else if (state.selectedLog.document === documentName) {
      displayAs = "current";
   } else if (docOrdering !== undefined && docOrdering > state.auditLogCount - 1) {
      displayAs = "hidden";
   } else if (docOrdering !== undefined && docOrdering > state.selectedLog.index) {
      displayAs = "future";
   }

   if (displayAs === "hidden") {
      return (<></>);
   } else if (connector_type === "arc_down") {
      const placePercentage = document.placePercentage;
      return <ActorGraphArcDocument srcActor={srcActor} dstActor={dstActor} envelope={envelope} displayAs={displayAs}
                                    docClick={docClick} id={documentName} scenario={scenario}
                                    placePercentage={placePercentage} key={document.dstActor}/>;
   } else if (connector_type === "arc_up") {
      const placePercentageRev = 100 - document.placePercentage;
      return <ActorGraphArcDocument srcActor={dstActor} dstActor={srcActor} envelope={envelope} displayAs={displayAs}
                                    docClick={docClick} id={documentName} scenario={scenario}
                                    placePercentage={placePercentageRev} key={document.dstActor}/>;
   } else {
      return <ActorGraphLineDocument srcActor={srcActor} dstActor={dstActor} envelope={envelope}
                                     docClick={docClick} id={documentName} scenario={state.scenario}
                                     displayAs={displayAs} placePercentage={document.placePercentage} key={document.dstActor}/>;
   }
}

type ConnectorType = "arc_up"|"arc_down"|"line";

// Return the type of connector between two DemoActors.
function getActorConnectorType(srcActor: DemoActor, dstActor: DemoActor): ConnectorType {
   return hasArc(srcActor, dstActor) ? "arc_down"
                                     : hasArc(dstActor, srcActor) ? "arc_up"  : "line";
}

// Graph the document contents if this document is shown
function graphDocumentContents(
    documentType: DocumentType,
    docClick: (e: React.MouseEvent) => void,
    showDocument: boolean,
    state: DemoGraphState,
) {
   if (showDocument) {
      const {x, y} = getDocumentCoordinates(documentType, state.scenario);
      return (
          <DocumentDetails documentType={documentType} state={state} x={x} y={y} docClick={docClick} id={documentType} key={documentType}/>
      );
   } else {
      return (<></>);
   }
}

// Return {x, y} of the document given by Name
function getDocumentCoordinates(documentName: string, scenario: DemoScenario) {
   const document = getScenarioDocuments(scenario)[documentName];
   const srcActor = nameToDemoActor(scenario)[document.srcActor];
   const dstActor = nameToDemoActor(scenario)[document.dstActor];
   const connector_type = getActorConnectorType(srcActor, dstActor);

   return connector_type === "arc_down" ? getArcDocumentPosition(srcActor, dstActor, document.placePercentage, scenario)
       : connector_type === "arc_up" ? getArcDocumentPosition(dstActor, srcActor, 100 - document.placePercentage, scenario)
           : getLineDocumentPosition(srcActor, dstActor, document.placePercentage, scenario);
}

// Draw a window that shows the details of a document, including sub-documents, description and parameters.
function DocumentDetails({documentType, x, y, docClick, id, state}: {
   documentType: DocumentType,
   x: number, y: number
   docClick: (e: React.MouseEvent) => void,
   id: string,
   state: DemoGraphState,
}) {
   const document = getScenarioDocuments(state.scenario)[documentType];
   const [tableHeight, setTableHeight] = useState(DocumentDetailsHeight);
   const [tableWidth, setTableWidth] = useState(DocumentDetailsWidth);
   const [tableYCoord, setTableYCoord] = useState(y);
   const onResize = useCallback((target: HTMLDivElement) => {
      if (tableHeight === DocumentDetailsHeight) {
         setTableHeight(target.clientHeight + 2);
      }
      if (tableWidth === DocumentDetailsWidth) {
         // Instead of hardcoded, should probably be border width read from target...
         // This can also be different depending on magnification
         setTableWidth(target.clientWidth + 2);
      }

      // TODO: Trying to stop from going of the bottom of the area, but looks like target.clientHeight is not right
      //       Adjusting by 50, but probably will not work in all circumstances
      if (tableYCoord + target.clientHeight + 50 > GraphHeight) {
         setTableYCoord(tableYCoord - ((tableYCoord + target.clientHeight + 50) - GraphHeight));
      }

   }, [tableYCoord, tableHeight, tableWidth]);
   const ref = useResizeObserver(onResize);

   let div_rows: JSX.Element[] = [<div key="header" className="document-title" onClickCapture={docClick} id={id}>{document.name}</div>];

   if (document.subDocuments !== undefined) {
      document.subDocuments.forEach((documentName) => {
         div_rows.push(<SubDocument documentName={documentName} scenario={state.scenario}/>);
      });
   }

   let formData = formDataFromState(documentType, state);
   const details = getDocumentDetails(documentType, formData, state.scenario);

   let i = 0;
   for (const data_line of details.data) {
      div_rows.push(<div key={documentType+"head"+i} className="document-contents-header">{data_line[0]}:</div>);
      div_rows.push(<div key={documentType+"data"+i} className="document-contents-data">{data_line[1]}</div>);
      i++;
   }

   div_rows.push(<div key={documentType+"type_head"} className="document-contents-header">DocType:</div>);
   div_rows.push(<div key={documentType+"type"} className="document-contents-doctype">{details.docType}</div>);
   div_rows.push(<div key={documentType+"desc"} className="document-contents-description" onClickCapture={docClick} id={id}>{details.description}</div>);

   const subDocuments = document.subDocuments !== undefined;
   return (<>
      <foreignObject x={x} y={tableYCoord} width={tableWidth} height={tableHeight}
         {...subDocuments ? "" : {onClickCapture: docClick}} id={id}>
         <div key="table" ref={ref} className="document-table">
            {div_rows}
         </div>
      </foreignObject>
   </>);
}

// Draws all sub documents.
function SubDocument({documentName, scenario}: {documentName: string, scenario: DemoScenario}) {
   let docState = useContext(DocStateContext);
   const clickDocument = useCallback((e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      e.nativeEvent.stopImmediatePropagation();

      docState.update(docState, e.currentTarget.id);
   }, [docState]);
   const document = getScenarioDocuments(scenario)[documentName];
   const envelope = document !== undefined && document.subDocuments !== undefined;

   return (<>
      <div key={`doc_icon_${documentName}`} className="document-contents-icon" onClick={clickDocument} id={documentName}>
         <DocumentIcon x={0} y={0} height={DocumentHeight} width={DocumentWidth} center={true} displayAs={"past"} folder={envelope} docClick={clickDocument} id={documentName}/>
      </div>
      <div key={`doc_details_${documentName}`} className="document-contents" onClick={clickDocument} id={documentName}>{document.name}</div>
   </>);
}

// True if there is an arc spanning from srcActor to dstActor.
//
// Not true if there is only an arc in the reverse direction.
function hasArc(srcActor: DemoActor, dstActor: DemoActor): boolean {
   let arcs = srcActor.arcs;
   if (arcs !== undefined) {
      for (let actorName of arcs) {
         if (actorName === dstActor.name) {
            return true;
         }
      }
   }
   return false;
}

// Draws the row header text for the simple asset exchange graph.
function SimpleRowHeaderText ({actorName, index, scenario}: {actorName: ActorType, index: number, scenario: DemoScenario}) {
   let rowDesc = actorNameToText(actorName, scenario);

   return (<text textAnchor="end" className="graph-row-header-text" x={ColumnWidth} y={centerY(index)}>{rowDesc}</text>)
}

//
// Actor Rectangles with Text
//

// Draws an actor rectangle on the SvgGraph.
function ActorGraphRect ({demoActor, state, gridClick}: {
   demoActor: DemoActor,
   state: DemoGraphState,
   gridClick?: (e: React.MouseEvent) => void,
}) {
   const selectedSrc = state.selectedSrc && state.selectedSrc.some(name => demoActor.name === name);
   const selectedDst = state.selectedDst && state.selectedDst.some(name => demoActor.name === name);
   const selectedClass = selectedSrc ? 'actor-selected-src' : selectedDst ? 'actor-selected-dst' : 'actor-plain';
   const classNames = ["actor-rect", selectedClass].join(" ");

   const actorLeft = actorToLeftX(demoActor);
   const actorTop = actorToTopY(demoActor, state.scenario);
   const actorWidth = actorToWidth(demoActor, state.scenario);
   const actorHeight = actorToHeight(demoActor);

   const gridBorder = 5;
   const gridWidth = DocumentWidth - 5;
   const gridLeft = actorLeft + actorWidth - (gridWidth + gridBorder);
   const gridTop = actorTop + (ActorHeight-gridWidth)/2;
   const hasLedger = demoActor.actorType === "custodian" || demoActor.actorType === "common_ledger"

   return (<>
          <rect className={classNames}
               x={actorLeft} y={actorTop}
               width={actorWidth} height={actorHeight}
               {...(hasLedger ? {onClickCapture: gridClick} : {})}
          />
         <ActorGraphText demoActor={demoActor} state={state}/>
         {hasLedger ?
             (<LedgerIcon
                 x={gridLeft} y={gridTop}
                 width={gridWidth} height={gridWidth}
              />) : ""}
         </>
   )
}

// Draws the ledger window of an "ledger" actor
function ActorGraphLedger ({demoActor, state, onClick}: {
   demoActor: DemoActor,
   state: DemoGraphState
   onClick?: (e: React.MouseEvent) => void,
}) {
   let actorLeft = actorToLeftX(demoActor);
   let actorTop = actorToTopY(demoActor, state.scenario);
   let actorWidth = actorToWidth(demoActor, state.scenario);

   let ledgerWidth = ColumnWidth * 3.5;
   let ledgerHeight = RowHeight * 4;
   let ledgerLeft = demoActor.name === "custodian1" || demoActor.name === "common_ledger1"
      ? actorLeft + actorWidth - ledgerWidth : actorLeft;
   let ledgerTop = actorTop + ActorHeight;

   // don't go outside of the display rectangle
   if (ledgerLeft < 0) {
      ledgerLeft = 0;
   } else if (ledgerLeft + ledgerWidth > GraphWidth) {
      ledgerLeft -= (ledgerLeft + ledgerWidth) - GraphWidth;
   }

   return (<ActorLedger
               x={ledgerLeft} y={ledgerTop}
               width={ledgerWidth} height={ledgerHeight}
               actor={demoActor}
               state={state}
               onClick={onClick}
         />
   );
}

// Draws the text inside an actor.
function ActorGraphText ({demoActor, state}: {demoActor: DemoActor, state: DemoGraphState}) {
   const displayNameMap = state.displayNames[demoActor.name]
   const displayName = displayNameMap !== undefined ? displayNameMap : demoActor.name;
   const scenario = state.scenario;

   return (
       <text className="actor-text" style={{userSelect: 'none'}} x={actorToCenterX(demoActor, scenario)} y={actorToCenterY(demoActor, scenario)} dominantBaseline="middle" textAnchor="middle">
          {displayName}
       </text>
   )
}

//
// Lines between Actor Rectangles
//

// Draws straight lines from the center of actors on the SvgGraph.
function ActorGraphLines ({srcActor, state}: {srcActor: DemoActor, state: DemoGraphState}) {
   let lines = srcActor.lines;

   if (lines === undefined || lines.length === 0) {
      return (<></>);
   } else {
      const scenario = state.scenario;
      return (<> {lines.map(dstActorName => (<ActorGraphLine srcActor={srcActor} dstActor={nameToDemoActor(scenario)[dstActorName]} state={state} key={dstActorName}/>))} </>)
   }
}

// Draws a straight line from the center of srcActor to dstActor on the SvgGraph.
function ActorGraphLine ({srcActor, dstActor, state}: {srcActor: DemoActor, dstActor: DemoActor, state: DemoGraphState}) {
   const scenario = state.scenario;
   const {srcX, srcY, dstX, dstY} = getLinePoints(srcActor, dstActor, scenario);
   const startArrow = state.flows?.some(({start, end}) => srcActor.name === end && dstActor.name === start);
   const endArrow = state.flows?.some(({start, end}) => srcActor.name === start && dstActor.name === end);
   const selected = startArrow || endArrow;
   const className = useMemo(() => ["connector-line", selected ? "connector-selected" : "connector-plain"], [selected]);

   return React.createElement("line", {
      x1: srcX,
      y1: srcY,
      x2: dstX,
      y2: dstY,
      className: className.join(" "),
      style: {stroke: "black"},
      ...(startArrow ? {markerStart: "url(#start_arrow)"} : {}),
      ...(endArrow ? {markerEnd: "url(#end_arrow)"} : {}),
   });
}

//
// Arcs between Actor Rectangles
//

// Draws arcing lines from the center of actors on the SvgGraph.
function ActorGraphArcs ({srcActor, state}: {srcActor: DemoActor, state: DemoGraphState}) {
   let arcs = srcActor.arcs;

   if (arcs === undefined || arcs.length === 0) {
      return (<></>);
   } else {
      return (<> {
         arcs.map(dstActorName => {
                  return React.createElement(
                    ActorGraphArc, {
                       key: dstActorName,
                       srcActor: srcActor,
                       dstActor: nameToDemoActor(state.scenario)[dstActorName],
                       state,
                    }
                  )
             }
         )
      } </>)
   }
}

// Draws an arc line from srcActor to dstActor.
function ActorGraphArc ({srcActor, dstActor, state}: {
   srcActor: DemoActor,
   dstActor: DemoActor,
   state: DemoGraphState
}) {
   const {toLeft, centerX, centerY, radiusX, radiusY} = arcInfoFromActors(srcActor, dstActor, state.scenario);
   const startAngle = 270;
   const sweep = toLeft ? -89: 89; // 90 extends the end arrow too far

   const showStartArrow = Boolean(state.flows?.some(({start, end}) => srcActor.name === end && dstActor.name === start));
   const showEndArrow = Boolean(state.flows?.some(({start, end}) => srcActor.name === start && dstActor.name === end));
   const selected = showStartArrow || showEndArrow;
   const arcId = `arc-${srcActor.name}-${dstActor.name}`;

   return (<GraphArc
             centerX={centerX} centerY={centerY} radiusX={radiusX} radiusY={radiusY}
             startAngle={startAngle} sweep={sweep}
             selected={selected}
             showStartArrow={showStartArrow} showEndArrow={showEndArrow}
             id={arcId}
          />)
}

// Draw a document icon on the arc between to document
function ActorGraphLineDocument ({srcActor, dstActor, envelope, displayAs, placePercentage, docClick, id, scenario}: {
   srcActor: DemoActor,
   dstActor: DemoActor,
   envelope: boolean,
   displayAs: AuditLogTime,
   placePercentage: number,
   docClick: (e: React.MouseEvent) => void,
   id: string,
   scenario: DemoScenario,
}) {
   const {x, y} = getLineDocumentPosition(srcActor, dstActor, placePercentage, scenario);

   return (<DocumentIcon
               x={x} y={y} height={DocumentHeight} width={DocumentWidth}
               center={true} displayAs={displayAs} folder={envelope}
               docClick={docClick} id={id}
      />);
}

// Return the x and y coordinates of a document placed between srcActor and DemoActor at placePercentage.
function getLineDocumentPosition(
   srcActor: DemoActor,
   dstActor: DemoActor,
   placePercentage: number,
   scenario: DemoScenario,
) {
   const {srcX, srcY, dstX, dstY} = getLinePoints(srcActor, dstActor, scenario);
   const x = srcX + (dstX - srcX) * placePercentage/100;
   const y = srcY + (dstY - srcY) * placePercentage/100;

   return {x, y}
}

function getLinePoints(
    srcActor: DemoActor,
    dstActor: DemoActor,
    scenario: DemoScenario,
) {
   const sameHeight = actorToTopY(srcActor, scenario) === actorToTopY(dstActor, scenario);
   const srcY = sameHeight ? actorToCenterY(dstActor, scenario) : actorToBotY(srcActor, scenario);
   const dstY = sameHeight ? srcY : actorToTopY(dstActor, scenario);

   let srcX = srcActor.fullWidth ? actorToCenterX(dstActor, scenario) : actorToCenterX(srcActor, scenario);
   let dstX = dstActor.fullWidth ? srcX : actorToCenterX(dstActor, scenario);
   // If actors are the same height, figure out which one is to the left/right of the other and set x appropriately
   if (sameHeight) {
      if (srcX < dstX) {
         dstX = actorToLeftX(dstActor);
         srcX = actorToRightX(srcActor, scenario);
      } else {
         srcX = actorToLeftX(srcActor);
         dstX = actorToRightX(dstActor, scenario);
      }
   }

   return {srcX, srcY, dstX, dstY}
}

// Draw a document icon on the arc between to document
function ActorGraphArcDocument ({srcActor, dstActor, envelope, displayAs, placePercentage, docClick, id, scenario}: {
   srcActor: DemoActor,
   dstActor: DemoActor,
   envelope: boolean,
   displayAs: AuditLogTime,
   placePercentage: number,
   docClick: (e: React.MouseEvent) => void,
   id: string,
   scenario: DemoScenario,
}) {
   const {x, y} = getArcDocumentPosition(srcActor, dstActor, placePercentage, scenario);

   return (<DocumentIcon
            x={x} y={y} height={DocumentHeight} width={DocumentWidth}
            center={true}
            displayAs={displayAs} folder={envelope}
            docClick={docClick} id={id}
      />);
}

// Return the x and y coordinates of a document placed between srcActor and DemoActor at placePercentage.
function getArcDocumentPosition(
    srcActor: DemoActor,
    dstActor: DemoActor,
    placePercentage: number,
    scenario: DemoScenario,
) {
   const {toLeft, centerX, centerY, radiusX, radiusY} = arcInfoFromActors(srcActor, dstActor, scenario);

   const degrees = 90/100 * placePercentage
   const theta = toLeft ? 270 - degrees : 270 + degrees;

   return arcToPointOnArc({cx: centerX, cy: centerY, rx: radiusX, ry: radiusY, theta: theta});
}


// Given src and dst actors, return the information needed to draw an arc inbetween them.
// Return:
//  * Start angle of the arc
//  * The center point of the arc
//  * The radius point of the arc
function arcInfoFromActors(srcActor: DemoActor, dstActor: DemoActor, scenario: DemoScenario) {
   // Are we going right or left?
   const toLeft = srcActor.column > dstActor.column;

   let offsetX = toLeft ? -ActorWidth/2 : ActorWidth/2;
   const centerX = actorToCenterX(srcActor, scenario) + offsetX;
   const centerY = actorToTopY(dstActor, scenario);

   const columnDelta = toLeft ? srcActor.column - dstActor.column : dstActor.column - srcActor.column;
   const rowDelta = actorNameToRow(scenario)[dstActor.actorType] - actorNameToRow(scenario)[srcActor.actorType];

   const radiusX = (ColumnWidth * columnDelta) - ActorWidth/2;
   const radiusY = (RowHeight * rowDelta) - ActorHeight/2;

   return {toLeft, centerX, centerY, radiusX, radiusY};
}

// Draws an arc line
//
// This just draws an arc.  It is otherwise ignorant of the graph.
function GraphArc ({centerX, centerY, radiusX, radiusY, startAngle, sweep, selected, showStartArrow, showEndArrow, id}: {
   centerX: number,
   centerY: number,
   radiusX: number,
   radiusY: number,
   startAngle: number,
   sweep: number,
   selected: boolean, // Whether the .arc-selected in its class
   showStartArrow: boolean,
   showEndArrow: boolean,
   id: string,
}) {
   // Sweep may be negative, so make two positive angles
   const angle1 = startAngle;
   const angle2 = (startAngle + sweep + 360) % 360;

   // Arc routine requires a clockwise change in angle, so reverse if counter-clockwise
   const clockwise = sweep >= 0;
   const [startArrowNormalized, endArrowNormalized, beginAngle, sweepNormalized] =
      clockwise ? [showStartArrow, showEndArrow, angle1, sweep]
                : [showEndArrow, showStartArrow, angle2, -sweep];

   const startArrowId = startArrowNormalized ? `${id}_start_arrow` : "";
   const endArrowId = endArrowNormalized ? `${id}_end_arrow` : "";

   const startMarker = (
       <marker id={startArrowId} markerWidth="10" markerHeight="8" refX="10" refY="4" orient="auto">
          {/* arrow pointing to the left: TR,BR,ML (refX, refY)*/}
          <polygon points="10,0 10,7 0,3.5"/>
       </marker>
   );
   const endMarker = (
       <marker id={endArrowId} markerWidth="10" markerHeight="8" refX="0" refY="4" orient="auto">
          {/* arrow pointing to the right: TL,RM (refX, refY),LB */}
          <polygon points="0,0 10,3.5 0,7"/>
       </marker>
   );
   return (
   <>
      {startArrowId ? startMarker : ""}
      {endArrowId ? endMarker : ""}
      <GraphArcInternal
         cx={centerX} cy={centerY} rx={radiusX} ry={radiusY} theta={beginAngle} delta={sweepNormalized} phi={0}
         selected={selected}
         startArrowId={startArrowId}
         endArrowId={endArrowId}
      />
   </>)
}

// Given the center and radius of an ellipse, and an angle, return the point that that angle intersects the ellipse.
//
// If we wanted to handle rotated ellipses, we would need to add an argument phi like GraphArcRaw.
function arcToPointOnArc({cx, cy, rx, ry, theta}: {
   cx: number, cy: number,
   rx: number, ry: number,
   theta: number
}): {x: number, y: number} {
   const thetaRad = theta * Math.PI / 180.0;
   const phiRad = 0 * Math.PI / 180.0;
   const x = cx + Math.cos(phiRad) * rx * Math.cos(thetaRad) + Math.sin(-phiRad) * ry * Math.sin(thetaRad);
   const y = cy + Math.sin(phiRad) * rx * Math.cos(thetaRad) + Math.cos(phiRad) * ry * Math.sin(thetaRad);

   return {x, y}
}

// Draws the requested Arc given params for center coords, radius, etc.
//
// cx, rx: center x and y coordinates of the ellipse
// rx, rx: x and y radius of the ellipse
//  theta: arc extent in degrees (start angle)
//  delta: rotation of the arc (sweep)
//    phi: x-axis rotation angle in degrees
//
// This function based on example from the book: SVG Essentials, Appendix F.
function GraphArcInternal({cx, cy, rx, ry, theta, delta, phi, selected, startArrowId, endArrowId}: {
   cx: number, cy: number,
   rx: number, ry: number,
   theta: number, delta: number, phi: number,
   selected: boolean,
   startArrowId: string, endArrowId: string
}) {
   const [arcMeasure, setArcMeasure] = useState<ReactElement>();
   const [arcFinal, setArcFinal] = useState<ReactElement>();
   const [arcMeasureDom, setArcMeasureDom] = useState<SVGPathElement>();
   const arcMeasureRef = useCallback((element: SVGPathElement) => {
      setArcMeasureDom(element)
   }, []);

   // Convert angles to radians
   const thetaRad = theta * Math.PI / 180.0;
   const deltaRad = delta * Math.PI / 180.0;
   const endThetaRad = (thetaRad + deltaRad) + Math.PI / 180.0;
   const phiRad = phi * Math.PI / 180.0;

   const x0 = cx + Math.cos(phiRad) * rx * Math.cos(thetaRad) + Math.sin(-phiRad) * ry * Math.sin(thetaRad);
   const y0 = cy + Math.sin(phiRad) * rx * Math.cos(thetaRad) + Math.cos(phiRad) * ry * Math.sin(thetaRad);

   const x1 = cx + Math.cos(phiRad) * rx * Math.cos(endThetaRad) + Math.sin(-phiRad) * ry * Math.sin(endThetaRad);
   const y1 = cy + Math.sin(phiRad) * rx * Math.cos(endThetaRad) + Math.cos(phiRad) * ry * Math.sin(endThetaRad);

   const largeArc = (delta > 180) ? 1 : 0;
   const sweep = (delta > 0) ? 1 : 0;

   const arcParams = `M ${x0},${y0} A ${rx},${ry} ${phi} ${largeArc},${sweep} ${x1},${y1}`
   const className = useMemo(() => ["connector-arc", selected ? "connector-selected" : "connector-plain"], [selected]);

   useEffect(() => {
      if (startArrowId === "" && endArrowId === "") {
         // If there are no arrows, draw the final arc
         setArcFinal((
             <path
                 d={arcParams}
                 className={className.join(" ")}
             />
         ))
      } else {
         // If there are arrows, draw the measurement arc
         setArcMeasure((
             <path
                 d={arcParams}
                 style={{stroke: "none", fill: "none"}}
                 ref={arcMeasureRef}
             />
         ))
      }
   }, [startArrowId, endArrowId, arcMeasureRef, arcParams, className])

   // If there are arrows, and we have the measurement arc, draw the final arc with arrows
   useEffect(() => {
      if (arcMeasureDom) {
         const startPoint = startArrowId ? arcMeasureDom.getPointAtLength(ArrowLength)
                                         : {x: x0, y: y0};
         const endPoint = endArrowId ? arcMeasureDom.getPointAtLength(arcMeasureDom.getTotalLength() - ArrowLength)
                                     : {x: x1, y: y1};
         const arcParams = `M ${startPoint.x},${startPoint.y} A ${rx},${ry} ${phi} ${largeArc},${sweep} ${endPoint.x},${endPoint.y}`
         const arc = (<path d={arcParams}
                            className={className.join(" ")}
                            {...(selected && startArrowId ? ({markerStart: `url(#${startArrowId})`}) : {})}
                            {...(selected && endArrowId ? ({markerEnd: `url(#${endArrowId})`}) : {})}
         />);
         setArcFinal(arc);
      }
   }, [arcMeasureDom, selected, startArrowId, endArrowId, className, largeArc, phi, rx, ry,
       sweep, x0, x1, y0, y1]);

   return (<>
      {arcMeasure}
      {arcFinal}
      </>);
}
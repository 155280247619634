// DemoActors are "Actors" in the AtomicNet ecosystem that represent AtomicNet participants.
//  * DemoActors exchange documents as part of AtomicNet transactions.
//  * DemoActors are represented as a rounded square box in SvgGraph.
//  * DemoActors are one of several ActorTypes, and each one has a unique ActorNameType.

import {ColumnOffset, ColumnWidth, ActorWidth, RowOffset, RowHeight, ActorHeight} from "./SvgGraph";

export type DemoScenario = 'custodian' | 'no_custodian' | 'implied_delivery' | 'no_exchange'

export type ActorType = "investment_advisor" | "oms" | "custodian" | "common_ledger" | "exchange" | "abc" | "dummy" | "dummy2";
export type ActorNameType = "ia1" | "ia2" | "oms1" | "oms2" | "custodian1" | "custodian2" |
                            "common_ledger1" | "common_ledger2" | "exchange" | "abc";

const MaxColumnCustodian = 6;
const MaxColumnNoCustodian = 4;

// DemoActor is an element in the demo graph represented by a rounded rectangle.
export interface DemoActor {
    actorType: ActorType;
    name: ActorNameType;
    column: number;
    lines?: ActorNameType[];
    arcs?: ActorNameType[];
    fullWidth?: boolean;
}

const ActorNameToText: { [actor: string]: string } = {
    investment_advisor: "Investment Advisor",
    oms: "OMS",
    dummy: "",
    custodian: "Custodian",
    common_ledger: "Bank / Central CP",
    exchange: "Exchange",
    abc: "",
}

const ActorNameToScenarioTextOverride: { [scenario: string]: {[actor: string]: string} } = {
    no_exchange: {
        custodian: "Custodian / LP Ledger",
        common_ledger: "Bank",
    }
}

export function actorNameToText(actor: string, scenario: DemoScenario) {
    let override = ActorNameToScenarioTextOverride[scenario];

    return (override && override[actor]) || ActorNameToText[actor];
}

const ScenarioActorTypes: { [scenario: string]: ActorType[] } = {
    custodian: ["investment_advisor", "oms", "dummy", "custodian", "common_ledger", "exchange", "abc"],
    no_custodian: ["investment_advisor", "oms", "dummy", "dummy2", "common_ledger", "exchange", "abc"],
    implied_delivery: ["investment_advisor", "oms", "dummy", "custodian", "common_ledger", "exchange", "abc"],
    no_exchange: ["investment_advisor", "oms", "dummy", "custodian", "common_ledger", "dummy2", "abc"],
}

export function getMaxColumn(scenario: DemoScenario): number {
    return scenario === 'no_custodian' ? MaxColumnNoCustodian : MaxColumnCustodian;
}

// Return all the ActorTypes that are used in the current demo scenario.
export function getScenarioActorTypes(scenario: DemoScenario): ActorType[] {
    return ScenarioActorTypes[scenario]
}

// Return all the DemoActors that are used in the current demo scenario.
export function getScenarioActors(scenario: DemoScenario): DemoActor[] {
    return ScenarioActors[scenario];
}

const BaseActors: DemoActor[] = [
    {
        actorType: "investment_advisor",
        name: "ia1",
        column: 0,
        lines: ["oms1"],
    }, {
        actorType: "investment_advisor",
        name: "ia2",
        column: MaxColumnCustodian,
        lines: ["oms2"],
    }, {
        actorType: "oms",
        name: "oms1",
        column: 0,
        lines: ["abc"],
        arcs: ["custodian1", "exchange"],
    }, {
        actorType: "oms",
        name: "oms2",
        column: MaxColumnCustodian,
        lines: ["abc"],
        arcs: ["custodian2", "exchange"],
    }, {
        actorType: "custodian",
        name: "custodian1",
        column: 1,
        arcs: ["common_ledger1", "common_ledger2"],
        lines: ["abc"],
    }, {
        actorType: "custodian",
        name: "custodian2",
        column: MaxColumnCustodian - 1,
        arcs: ["common_ledger1", "common_ledger2"],
        lines: ["abc"],
    }, {
        actorType: "common_ledger",
        name: "common_ledger1",
        column: 2,
        lines: ["abc"],
    }, {
        actorType: "common_ledger",
        name: "common_ledger2",
        column: MaxColumnCustodian - 2,
        lines: ["abc"],
    }, {
        actorType: "exchange",
        name: "exchange",
        column: 3,
        lines: ["abc"],
    }, {
        actorType: "abc",
        name: "abc",
        column: 0,
        fullWidth: true,
    },
];

// For each scenario, list any actors in the base set that should not
// be included in that scenario.
const ScenarioRemoveActors: { [scenario: string]: ActorNameType[] } = {
    "custodian": [],
    "no_custodian": ["custodian1", "custodian2"],
    "implied_delivery": ["common_ledger1"],
    "no_exchange": ["exchange", "common_ledger2"],
}

// A structure that represents modifications of an actor from a base actor.
interface AtomicNetActorOptions {
    column?: number,
    arcs?: ActorNameType[],
    lines?: ActorNameType[],
}

// For each scenario, list any actors that should be modified from the base, and how they should
// be modified (add remove lines and arcs to other actors, and which column it belongs in)
const ScenarioModifyActors: { [scenario: string]: Map<ActorNameType, AtomicNetActorOptions> } = {
    "custodian": new Map([]),
    "implied_delivery": new Map([
        ["custodian1", {
            column: 1,
            arcs: ["common_ledger2"],
            lines: ["abc"],
        }], ["custodian2", {
            column: MaxColumnCustodian - 1,
            arcs: ["common_ledger2"],
            lines: ["abc"],
        }],
    ]),
    "no_custodian": new Map([
        ["ia2", {
            column: MaxColumnNoCustodian,
        }], ["oms1", {
            lines: ["abc"],
            arcs: ["common_ledger1", "common_ledger2", "exchange"],
        }], ["oms2", {
            column: MaxColumnNoCustodian,
            lines: ["abc"],
            arcs: ["common_ledger1", "common_ledger2", "exchange"],
        }], ["common_ledger1", {
            column: 1,
            lines: ["abc"],
        }], ["common_ledger2", {
            column: MaxColumnNoCustodian - 1,
        }], ["exchange", {
            column: 2,
            lines: ["abc"],
        }]
    ]),
    "no_exchange": new Map([
        ["ia1", {
            lines: ["oms1"],
        }], ["ia2", {
            lines: ["oms2"],
        }], ["oms1", {
            lines: ["abc", "oms2"],
            arcs: ["custodian1"],
        }], ["oms2", {
            lines: ["abc"],
            arcs: ["custodian2"],
        }], ["custodian1", {
            arcs: ["common_ledger1"],
            lines: ["abc", "custodian2"],
        }], ["custodian2", {
            arcs: ["common_ledger1"],
            lines: ["abc"],
        }],
    ]),
}

// To be run once per scenario, generate the actors to be shown in a scenario.
function generateScenarioActors(scenario: DemoScenario): DemoActor[] {
    let actors: DemoActor[] = [];
    let removeActors = ScenarioRemoveActors[scenario];
    let modifyActors = ScenarioModifyActors[scenario];

    BaseActors.forEach((actor) => {
        if (!removeActors.includes(actor.name)) {
            let new_actor: DemoActor = {
                actorType: actor.actorType,
                arcs: actor.arcs,
                column: actor.column,
                fullWidth: actor.fullWidth,
                lines: actor.lines,
                name: actor.name
            };
            let modify = modifyActors.get(actor.name);

            if (modify !== undefined) {
                if (modify.column !== undefined) {
                    new_actor.column = modify.column;
                }
                if (modify.lines !== undefined) {
                    new_actor.lines = modify.lines;
                }
                if (modify.arcs !== undefined) {
                    new_actor.arcs = modify.arcs;
                }
            }
            actors.push(new_actor);
        }
    });

    return actors;
}

const ScenarioActors: { [scenario: string]: DemoActor[] } = {
    no_custodian: generateScenarioActors("no_custodian"),
    implied_delivery: generateScenarioActors("implied_delivery"),
    custodian: generateScenarioActors("custodian"),
    no_exchange: generateScenarioActors("no_exchange"),
}

// Given a graph actor name, return the DemoActor.
export function nameToDemoActor(scenario: DemoScenario): {[p: string]: DemoActor} {
    return Object.fromEntries(getScenarioActors(scenario).map((demoActor) => [demoActor.name, demoActor]));
}

// Given a graph actor, return the center x coordinate of an actor rectangle.
export function actorNameToRow(scenario: DemoScenario): {[p: string]: number} {
    return Object.fromEntries(getScenarioActorTypes(scenario).map((actorType, index) => [actorType, index]));
}

///
/// Helper functions for finding the edges and centers of actor rectangles.
///

// Given a zero based row, return the top y coordinate of an actor rectangle.
function topY(row: number): number {
    return RowOffset + RowHeight * row
}

// Given a zero based column, return the center y coordinate of an actor rectangle.
export function centerY(column: number): number {
    return RowOffset + RowHeight * column + (ActorHeight / 2)
}

// Given a graph actor, return the top y coordinate of an actor rectangle.
export function actorToTopY(actor: DemoActor, scenario: DemoScenario): number {
    return topY(actorNameToRow(scenario)[actor.actorType]);
}

export function actorToHeight(actor: DemoActor): number {
    return actor.fullWidth ? ActorHeight * 2 : ActorHeight;
}

// Given a graph actor, return the bottom y coordinate of an actor rectangle.
export function actorToBotY(actor: DemoActor, scenario: DemoScenario): number {
    return actorToTopY(actor, scenario) + actorToHeight(actor);
}

// Given a graph actor, return the center y coordinate of an actor rectangle.
export function actorToCenterY(actor: DemoActor, scenario: DemoScenario): number {
    return actorToTopY(actor, scenario) + (actorToHeight(actor) / 2);
}

export function actorToWidth(actor: DemoActor, scenario: DemoScenario): number {
    return actor.fullWidth ? ColumnWidth * getMaxColumn(scenario) + ActorWidth : ActorWidth;
}

// Given an graph actor, return the left x coordinate of an actor rectangle.
export function actorToLeftX(actor: DemoActor): number {
    return ColumnOffset + ColumnWidth * (actor.column + 1);
}

export function actorToRightX(actor: DemoActor, scenario: DemoScenario): number {
    return actorToLeftX(actor) + actorToWidth(actor, scenario);
}

export function actorToCenterX(actor: DemoActor, scenario: DemoScenario): number {
    return actorToLeftX(actor) + (actorToWidth(actor, scenario) / 2);
}
import React from 'react';
import {AuditLog } from './features/audit_log/auditLog'
import { OmsInput } from './features/order_input/orderInput';
import './App.css';

import './assets/fonts/Avenir Next.ttc'



function App() {
  return (
    <div className="App an-oms-demo">
      <OmsInput />
        <AuditLog />
    </div>
  );
}

export default App;

import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {DemoScenario} from "../SvgGraph/DemoActor";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {onScenarioSelect} from "../audit_log/auditSlice";

export const InitialScenario: DemoScenario = "custodian";

export default function ScenarioDropdown() {
    const dispatch = useAppDispatch()
    const handleChange = (event: SelectChangeEvent) => {
        scenario = event.target.value as DemoScenario;
        dispatch(onScenarioSelect(scenario));
    };
    let scenario = useAppSelector(state => state.audit.scenario);

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Scenario</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={scenario}
                    label="Scenario"
                    onChange={handleChange}
                >
                    <MenuItem value="custodian">Custodian with Common Ledgers</MenuItem>
                    <MenuItem value="no_custodian">Custodial Bank & Central CP</MenuItem>
                    <MenuItem value="implied_delivery">Fedwire</MenuItem>
                    <MenuItem value="no_exchange">Principal Transaction</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

//
// Represent an Actor's ledger for display.
//

// TODO:
//   * Right justify numbers

import React from "react";
import {ActorNameType, DemoActor} from "./DemoActor";
import {DemoGraphState} from "./DemoGraphState";

export type AccountEventType = "starting_balance"|"escrow"|"transfer"|"debit"|"credit"|"fedwire_debit"|"fedwire_credit";
export type AccountType = "ia1" | "ia2" | "fiat" | "asset";

export interface LedgerEvent {
    time: string,
    ledger_holder: ActorNameType,
    account: AccountType,
    event: AccountEventType,
    amount1: number,
    amount2?: number,
    in_future: boolean
}

const LedgerEventToText: {[event:string]:string} = {
    starting_balance: "Starting Balance",
    escrow: "Escrow Funds",
    transfer: "Transfer Funds",
    debit: "Debit Funds",
    credit: "Credit Funds",
    fedwire_debit: "Fedwire Debit Funds",
    fedwire_credit: "Fedwire Credit Funds",
}

interface LedgerRow {
    time: string,
    inFuture: boolean,
    event: string,
    account1Balance: number|undefined,
    account2Balance: number|undefined,
    account1Escrow: number|undefined,
    account2Escrow: number|undefined,
}

function eventsToTable(events: LedgerEvent[], ledger_holder: ActorNameType): LedgerRow[] {
    let ledgerTable: LedgerRow[] = [];
    let cur_row = {
        time: "",
        event: "",
        inFuture: false,
        account1Balance: 0,
        account2Balance: 0,
        account1Escrow: 0,
        account2Escrow: 0,
    };

    events.forEach((event, index) => {
        if (event.time && event.ledger_holder === ledger_holder) {
            cur_row.time = event.time;
            cur_row.event = event.event;

            if (event.event === "starting_balance") {
                cur_row.account1Balance = event.amount1;
                cur_row.account2Balance = event.amount2 || 0;
            } else if (event.event === "escrow") {
                if (event.account === "ia1" || event.account === "fiat") {
                    cur_row.account1Balance -= event.amount1;
                    cur_row.account1Escrow += event.amount1;
                } else {
                    cur_row.account2Balance -= event.amount1;
                    cur_row.account2Escrow += event.amount1;
                }
            } else if (event.event === "transfer") {
                if (event.account === "ia1" || event.account === "fiat") {
                    cur_row.account2Balance += event.amount1;
                    cur_row.account1Escrow -= event.amount1;
                } else {
                    cur_row.account1Balance += event.amount1;
                    cur_row.account2Escrow -= event.amount1;
                }
            } else if (event.event === "debit" || event.event === "fedwire_debit") {
                if (event.amount1 !== undefined) {
                    if (event.account === "ia1") {
                        cur_row.account2Escrow -= event.amount1;
                    } else {
                        cur_row.account1Escrow -= event.amount1;
                    }
                }
                if (event.amount2 !== undefined) {
                    if (event.account === "ia1") {
                        cur_row.account2Escrow -= event.amount2;
                    } else {
                        cur_row.account2Escrow -= event.amount2;
                    }
                }
            } else if (event.event === "credit" || event.event === "fedwire_credit") {
                if (event.amount1 !== undefined) {
                    if (event.account === "ia1") {
                        cur_row.account1Balance += event.amount1;
                    } else {
                        cur_row.account1Balance += event.amount1;
                    }
                }
                if (event.amount2 !== undefined) {
                    if (event.account === "ia1") {
                        cur_row.account2Balance += event.amount2;
                    } else {
                        cur_row.account2Balance += event.amount2;
                    }
                }
            }

            ledgerTable.push({
                time: cur_row.time,
                inFuture: event.in_future,
                event: cur_row.event,
                account1Balance: cur_row.account1Balance,
                account2Balance: cur_row.account2Balance,
                account1Escrow: cur_row.account1Escrow,
                account2Escrow: cur_row.account2Escrow,
            });
        }
    });

    return ledgerTable;
}

function numberWithCommas(num: number) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const CurrencyMap = new Map<string|undefined, string>([
    ["USD", "$"],
    ["EUR", "€"],
    ["YEN", "¥"],
    ["GBP", "£"],
]);

export function formatAssetAmount(num: number|string|undefined, assetType: string|undefined) {
    if (typeof num === "number") {
        const numWithCommas = numberWithCommas(num);
        let symbol = CurrencyMap.get(assetType) || '';

        return symbol + numWithCommas;
    } else {
        return num;
    }
}

function LedgerNumber({cur_value, prev_value, asset, inFuture}: {cur_value: number|undefined, prev_value: number|undefined, asset: string, inFuture: boolean, key: string}) {
    let ledger_class = inFuture ? "ledger-text-future" : "ledger-text";

    if (cur_value !== undefined && prev_value !== undefined) {
        if (cur_value > prev_value) {
            ledger_class += "-positive";
        } else if (cur_value < prev_value) {
            ledger_class += "-negative";
        }
    }

    return <div key="ledger_number" className={"ledger-align-number " + ledger_class}>{formatAssetAmount(cur_value, asset)}</div>
}

/// Display a window that contains an Actor's ledger, which balances associated with log events.
export function ActorLedger({x, y, height, width, actor, state, onClick}: {
    x: number,
    y: number,
    height: number,
    width: number,
    actor: DemoActor,
    state: DemoGraphState
    onClick?: (e: React.MouseEvent) => void,
}) {
    const noCustodian = state.scenario === "no_custodian";
    const table = eventsToTable(state.ledgerEvents, actor.name);
    const custodianLedger = actor.actorType === "custodian";
    const account1Owner = custodianLedger || noCustodian ? "ia1" : "custodian1";
    const account2Owner = custodianLedger || noCustodian ? "ia2" : "custodian2";
    let account1 = state.displayNames[account1Owner] || account1Owner;
    let account2 = state.displayNames[account2Owner] || account2Owner;

    // Need a shorter name to make it fit...
    account1 = account1.replace("Counterparty Custodian", "CP Custodian");
    account2 = account2.replace("Counterparty Custodian", "CP Custodian");

    const asset1 = state.asset1;
    const asset2 = state.asset2;

    const titleDetailMap: { [actor: string]: { ledgerTitleType: string}} = {
        custodian1: {
            ledgerTitleType: account1,
        },
        custodian2: {
            ledgerTitleType: account2,
        },
        common_ledger1: {
            ledgerTitleType: asset1,
        },
        common_ledger2: {
            ledgerTitleType: asset2,
        },
    };
    const ledgerDetails = titleDetailMap[actor.name];
    const titleType: { [actor: string]: { ledgerTitle: string, balanceTitle1: string, balanceTitle2: string, asset1: string, asset2: string}} = {
        custodian: {
            ledgerTitle: "Ledger Account",
            balanceTitle1: asset1,
            balanceTitle2: asset2,
            asset1: asset1,
            asset2: asset2,
        },
        common_ledger: {
            ledgerTitle: "Ledger Asset",
            balanceTitle1: account1,
            balanceTitle2: account2,
            asset1: ledgerDetails.ledgerTitleType,
            asset2: ledgerDetails.ledgerTitleType,
        },
    };
    const titleDetails = titleType[actor.actorType];

    let div_rows = [
        <>
            <div key="asset_header" className="ledger-table-title">{titleDetails.ledgerTitle}: {ledgerDetails.ledgerTitleType}</div>
            <div key="time_header" className="ledger-table-header">Time</div>
            <div key="balance_header1" className="ledger-table-header">{titleDetails.balanceTitle1}<br></br>Balance</div>
            <div key="escrow_header1" className="ledger-table-header">{titleDetails.balanceTitle1}<br></br>Escrow</div>
            <div key="balance_header2" className="ledger-table-header">{titleDetails.balanceTitle2}<br></br>Balance</div>
            <div key="escrow_header2" className="ledger-table-header">{titleDetails.balanceTitle2}<br></br>Escrow</div>
            <div key="description_header" className="ledger-table-header">Description</div>
        </>
    ];
    let prev_row = table[0];
    table.forEach((row, index) => {
        const inFuture = row.inFuture;
        const ledgerClass = inFuture ? 'ledger-text-future' : 'ledger-text';

        // &nbsp is a hack to get the spacing right.  Should be able to do it with the grid in CSS.
        div_rows.push(
            <>
                <div key={`time_${index}`} className={ledgerClass}>{row.time}&nbsp;</div>
                <LedgerNumber key={`balance1_${index}`} cur_value={row.account1Balance} prev_value={prev_row.account1Balance} asset={titleDetails.asset1} inFuture={inFuture}/>
                <LedgerNumber key={`escrow1_${index}`} cur_value={row.account1Escrow} prev_value={prev_row.account1Escrow} asset={titleDetails.asset1} inFuture={inFuture}/>
                <LedgerNumber key={`balance2_${index}`} cur_value={row.account2Balance} prev_value={prev_row.account2Balance} asset={titleDetails.asset2} inFuture={inFuture}/>
                <LedgerNumber key={`escrow2_${index}`} cur_value={row.account2Escrow} prev_value={prev_row.account2Escrow} asset={titleDetails.asset2} inFuture={inFuture}/>
                <div key={`desc_${index}`} className={ledgerClass}>&nbsp;{LedgerEventToText[row.event]}</div>
            </>
        );

        prev_row = row;
    });

    return (<>
        <foreignObject x={x} y={y} width={width} height={height}>
            <div key="ledger-table" className="ledger-table" onClickCapture={onClick}>
                {div_rows}
            </div>
        </foreignObject>
    </>);
}


import { useAppSelector, useAppDispatch } from '../../app/hooks'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {AuditEntry, onStepSelection} from "../audit_log/auditSlice";
import { Tooltip } from 'react-tooltip'
import {ActorNameType} from "../SvgGraph/DemoActor";

export interface OrderInput {
  clientOne: string;
  clientTwo: string;
  purchasingEntity: string;
  omsOne: string;
  omsTwo: string;
  custodianA: string;
  custodianB: string;
  assetOne: string; // What clientTwo wants
  assetTwo: string; // What clientOne wants
  exchange: string;
  quantity: number;
}

type LogEntryType = "Investment Advisor" | "OMS" | "Custodian" | "Counterparty Custodian" | "Primary Asset Ledger" | "Bank" | "Exchange" | "ABC";
export const actorToLogDisplayName = new Map<ActorNameType, LogEntryType>([
  ["ia1", "Investment Advisor"],
  ["oms1", "OMS"],
  ["custodian1", "Custodian"],
  ["custodian2", "Counterparty Custodian"],
  ["common_ledger1", "Primary Asset Ledger"],
  ["common_ledger2", "Bank"],
  ["exchange", "Exchange"],
  ["abc", "ABC"],
]);

export function AuditLog() {
  const dispatch = useAppDispatch()
  let scroller = document.querySelector("#tableBorder");
  let idxForScrolling = useAppSelector(state => state.audit.idxForScrolling);
  if (scroller) {
  if (idxForScrolling > 0) {
      scroller.scrollTop = (idxForScrolling - 1) * 65;
    }
  }

  function showMeStepDetail(step : AuditEntry) {
    dispatch(onStepSelection(step));
  }
  
  let auditLog = useAppSelector(state => state.audit.auditEntries);
  return (
        <div>

<div className="tooltip-container">
  <Tooltip id="my-tooltip"/>
</div>

            <div className='contentCard' id="theAuditLog">
              {/* <h3>Consolidated Audit Log - STYLE ME</h3> */}
        <TableContainer component={Paper} sx={{ maxHeight: 440 }} id="tableBorder">
        <Table  aria-label="sticky table" stickyHeader id="scrollingBody">
          <TableHead>
            <TableRow>
              <TableCell className="headerCell" align="left" sx={{width: 200}}>TIMESTAMP</TableCell>
              <TableCell className="headerCell" align="center" sx={{width: 10}}>INFO</TableCell>
              <TableCell className="headerCell" align="left" sx={{width:1000}}>ACTION</TableCell>
              {/* <TableCell align="right">Action</TableCell> */}
              {/* <TableCell align="right">Event</TableCell> */}
              <TableCell className="headerCell" align="right" sx={{width:200}}>SENDER</TableCell>
              <TableCell className="headerCell" align="right" sx={{width:200}}>RECEIVER</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {auditLog.map((item) =>
                <TableRow onClick={() => showMeStepDetail(item)} className={item.selected} key={item.timestamp}>
                    <TableCell align="left" id="dateCell">{new Date(item.timestamp).toISOString()}</TableCell>
                    <TableCell align="center">
                      <b data-tooltip-id="my-tooltip"
                        data-tooltip-html={item.description}
                        data-tooltip-place="bottom">
                        <svg fill="#005599" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                             width="25px" height="25px" viewBox="0 0 416.979 416.979">
                          <g>
                            <path d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85
                              c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786
                              c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576
                              c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765
                              c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"/>
                          </g>
                        </svg>
                      </b>
                    </TableCell>
                    <TableCell align="left">
                      <b className="logAction">{item.event}</b>
                      : {item.message}
                      {/* Description (tooltip): {item.description} */}
                    </TableCell>
                    <TableCell align="right">{actorToLogDisplayName.get(item.src)}</TableCell>
                    <TableCell align="right">{actorToLogDisplayName.get(item.dst)}</TableCell>
                </TableRow>  
            )}
          </TableBody>
        </Table>
      </TableContainer>
            </div>
            {/* End  Audit Log Table*/}
            {/* Begin Ledger Table*/}
            
        </div>
  )
}